@import '/src/app/core/styles/variables/variables';

.menu-user {
  .log-out {
    .p-menu-item-link {
      color: $color-primary;
    }
  }
}

.field {
  margin-bottom: 0.5rem;
  min-height: 83px;
}

.form-error {
  visibility: hidden;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  color: #c8102e;
}

.ng-dirty.ng-invalid {
  & ~ .form-error {
    visibility: visible;
  }
}

.p-button.p-component:disabled {
  cursor: not-allowed;
}

.p-button.btn {
  padding: 11px 24px;
  &-cancel {
    background-color: $color-white-primary-04;
    border: 1px solid $color-white-primary-04;
    color: $color-black-primary-01;
    &:not(:disabled):hover {
      background-color: $color-white-primary-04-hover;
      border: 1px solid $color-white-primary-04;
      color: $color-black-primary-01;
    }
  }
  &-select {
    background-color: $color-black-primary-02;
    border: 1px solid $color-black-primary-02;
    color: $color-white-primary-03;
    &:not(:disabled):hover {
      background-color: $color-black-primary-02-hover;
      border: 1px solid $color-black-primary-02;
      color: $color-white-primary-03;
    }
  }
}

.p-button.btn-transparent {
  background-color: transparent;
  color: $color-black-primary-01;
  border-color: transparent;
  font-weight: 700;
  font-size: 14px;
  &:not(:disabled):hover {
    background-color: transparent;
    border-color: transparent;
    color: $color-black-primary-01;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.p-button.btn-icon-save,
.p-button.btn-icon-delete {
  cursor: pointer;
  &.p-button-text {
    color: $color-black-primary-01;
  }
  &:not(:disabled):hover {
    background-color: $color-white-primary-05;
    border-color: transparent;
    color: $color-black-primary-01;
  }
}

.p-button.btn-view-detail,
.p-button.btn-view-detail-dark,
.p-button.btn-outlined-danger,
.p-button.btn-primary,
.p-button.btn-contrast,
.p-button.btn-outlined-dark,
.p-button.btn-secondary-gray {
  &:focus-visible {
    background-color: none;
    color: none;
    border-color: none;
    box-shadow: none;
  }
}

.p-button.btn-secondary-gray {
  background-color: $color-white-primary-04;
  color: $color-black-primary-01;
  border-color: $color-white-primary-02;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  &:not(:disabled):hover {
    background-color: $color-white-primary-04;
    color: $color-black-primary-01;
    border-color: $color-white-primary-02;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
}

.p-button.btn-contrast {
  background-color: $color-black-primary-02;
  color: $color-white-primary-03;
  border-color: $color-black-primary-02;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  &:not(:disabled):hover {
    background-color: $color-black-primary-02;
    color: $color-white-primary-03;
    border-color: $color-black-primary-02;
    box-shadow: none;
  }
}

.p-button.btn-primary {
  background-color: $color-primary;
  color: $color-white-primary-03;
  border-color: $color-primary;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  &:not(:disabled):hover {
    background-color: $color-primary;
    color: $color-white-primary-03;
    border-color: $color-primary;
    box-shadow: rgba(138, 2, 2, 0.2) 0px 2px 8px 0px;
  }
}

.p-button.btn-view-detail-dark {
  background-color: none;
  color: $color-prey-primary-02;
  border-color: none;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 0;
  &:not(:disabled):hover {
    background-color: none;
    color: $color-prey-primary-02;
    border-color: none;
    box-shadow: none;
    text-decoration: underline;
  }
}

.p-button.btn-view-detail {
  color: $color-icon-history;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  padding-left: 0;
  text-align: left;
  &:not(:disabled):hover {
    background-color: none;
    color: $color-icon-history;
    border-color: none;
    box-shadow: none;
    text-decoration: underline;
  }
}

.p-button.btn-outlined-danger {
  background: none;
  color: $color-primary;
  border-color: $color-primary;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  &:not(:disabled):hover {
    background: #fad6db;
    color: $color-primary;
    border-color: $color-primary;
    box-shadow: none;
  }
}

.p-button.btn-outlined-dark {
  background: none;
  color: $color-black-primary-01;
  border-color: $color-white-primary-02;
  font-weight: 700;
  font-size: 14px;
  border-radius: 4px;
  &:not(:disabled):hover {
    background: $color-white-primary-05;
    color: $color-black-primary-01;
    border-color: $color-white-primary-02;
    box-shadow: none;
  }
}

.p-selectbutton {
  .btn-selected {
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    &.p-togglebutton {
      &.p-togglebutton-checked {
        background-color: $color-black-primary-02;
        color: $color-white-primary-03;
        border-color: $color-black-primary-02;
        &:not(:disabled):hover {
          background-color: $color-black-primary-02;
          color: $color-white-primary-03;
          border-color: $color-black-primary-02;
          box-shadow: none;
        }
      }
    }
  }
}

.p-select {
  flex-wrap: wrap;
  &.custom-select {
    background: transparent;
    &.p-select:not(.p-disabled) {
      &:hover {
        border-color: $color-white-primary-02;
      }
      &.p-focus {
        border-color: $color-white-primary-02;
        box-shadow:
          rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      }
    }
    .p-select-option.p-select-option-selected {
      background: $color-white-primary-02;
      color: $color-black-primary-01;
      font-weight: 700;
      &.p-focus {
        background: $color-white-primary-02;
        color: $color-black-primary-01;
      }
    }
  }
  &.input-solution-select {
    &.temperature-select.p-select-label.p-placeholder {
      color: $color-black-primary-02;
      font-size: 14px;
    }
    &.p-select:not(.p-disabled) {
      &:hover {
        border-color: $color-white-primary-02;
      }
      &.p-focus {
        border-color: $color-white-primary-02;
        box-shadow:
          rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      }
    }
    .p-select-option.p-select-option-selected {
      color: $color-white-primary-02;
    }
  }
}

.p-steps.step-horizontal-item {
  .p-steps-list .p-steps-item {
    &::before {
      border-color: $color-prey-primary-02;
    }
    &:first-child::before {
      width: 100%;
      transform: none;
      border-color: $color-prey-primary-02;
    }
    &:last-child::before {
      width: 100%;
      transform: none;
      border-color: $color-prey-primary-02;
    }
    .p-steps-item-link {
      .p-steps-item-number {
        color: $color-black-primary-02;
        min-width: unset;
        width: 24px;
        height: 24px;
        background: $color-white-primary-01;
        border: 1px solid $color-white-primary-02;
        font-size: 12px;
        font-weight: 700;
      }
      .p-steps-item-label {
        color: $color-white-primary-01;
        font-size: $font-primary;
        line-height: 16px;
      }
    }
  }
  .p-steps-list .p-steps-item.highlight-step {
    .p-steps-item-number {
      background-color: $color-step-bg;
      border-color: $color-step-border;
      color: $color-black-primary-02;
    }
  }
  .p-steps-list .p-steps-item.highlight-step.p-steps-item-active {
    .p-steps-item-label {
      font-weight: 700;
    }
  }
}

.p-datatable {
  &.custom-table {
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      background-color: $color-white;
      max-width: 250px;
    }
    .p-datatable-inline-filter {
      > p-button {
        display: none;
      }
      .p-inputtext {
        &:enabled:focus,
        &:enabled:hover {
          border-color: $color-prey-primary-01;
          box-shadow: none;
        }
      }
      .p-select {
        &:not(.p-disabled):hover,
        &:not(.p-disabled).p-focus {
          border-color: $color-prey-primary-01;
          box-shadow: none;
        }
      }
    }
    .p-datatable-header {
      background-color: $color-white;
      .p-iconfield {
        .p-inputtext {
          border-color: $color-white-primary-02;
          background-color: $color-white-primary-01;
          &:enabled:focus {
            border-color: $color-prey-primary-03;
            box-shadow: none;
          }
          &:enabled:hover {
            border-color: $color-prey-primary-03;
          }
        }
      }
    }
    .p-datatable-thead > tr > th,
    .p-datatable-tbody > tr > td {
      .p-checkbox {
        .p-checkbox-input {
          background: transparent;
          border: 1px solid $color-black-primary-02;
          opacity: 1;
          &:hover {
            background: $color-white-primary-01;
            border: 1px solid $color-black-primary-02;
          }
        }
        &.p-checkbox-checked {
          .p-checkbox-input {
            opacity: 0;
          }
          &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
            border-color: $color-black-primary-02;
            background: $color-black-primary-02;
          }
          .p-checkbox-box {
            border-color: $color-black-primary-02;
            background: $color-black-primary-02;
          }
        }
      }
    }
    sortalticon,
    sortamountupalticon,
    sortamountdownicon {
      display: none;
    }
  }
}

.p-paginator.custom-paginator {
  justify-content: flex-end;
  &.custom-tab-options-paginator {
    .p-paginator-current {
      position: absolute;
      top: 16px;
      left: 174px;
      color: $color-prey-primary-02;
      border-left: 2px solid $color-white-primary-02;
      padding: 7px 0 7px 14px;
    }
  }
  .p-paginator-current {
    position: absolute;
    top: 25px;
    left: 10px;
    color: $color-prey-primary-02;
  }
  .p-paginator-page.p-paginator-page-selected {
    background-color: $color-white-primary-02;
    color: $color-black-primary-01;
    border-radius: 4px;
  }
  .p-select {
    flex-wrap: wrap;
    background: transparent;
    &.p-select:not(.p-disabled) {
      &:hover {
        border-color: $color-white-primary-02;
      }
      &.p-focus {
        border-color: $color-white-primary-02;
        box-shadow:
          rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
          rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
      }
    }
  }
}

.p-datatable-mask.p-overlay-mask {
  position: absolute;
}

.p-dialog {
  &.dynamic-dialog,
  &.session-history-dialog {
    background: $color-white-primary-01;
    .p-dialog-header,
    .p-dialog-content {
      background: $color-white-primary-01;
    }
  }
}

.p-tag {
  &.tag-status {
    border-radius: 14px;
    padding: 2px 6px;
    width: max-content;
    height: fit-content;
    padding: 4px 8px;
    font-size: 10px;
    font-weight: 700;
    width: 100%;
    text-transform: uppercase;
    color: $color-white-primary-01;
  }

  &.approved {
    background-color: $color-success-bg;
  }

  &.cancelled {
    background-color: $color-prey-primary-02;
  }

  &.diagnosis-completed {
    background-color: $color-blue-primary-01;
  }

  &.in-progress {
    background-color: $color-yellow-primary-01;
    color: $color-black-primary-01;
  }
}

.p-checkbox {
  &.checkbox-active {
    .p-checkbox-input {
      background: $color-white-primary-01;
      border: 1px solid $color-black-primary-02;
      opacity: 1;
      &:hover {
        background: $color-white-primary-01;
        border: 1px solid $color-black-primary-02;
      }
    }
  }
  &.checkbox-permission {
    .p-checkbox-input {
      border-color: $color-black-primary-02;
      opacity: 1;
      &:hover {
        background: none;
      }
    }
  }
  &.checkbox-active,
  &.checkbox-permission {
    &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
      border-color: $color-black-primary-02;
    }
    &.p-checkbox-checked {
      .p-checkbox-input {
        opacity: 0;
      }
      &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
        border-color: $color-black-primary-02;
        background: $color-black-primary-02;
      }
      .p-checkbox-box {
        border-color: $color-black-primary-02;
        background: $color-black-primary-02;
      }
    }
  }
}

.p-toast-close-icon {
  color: $color-black-primary-01;
}

.p-component {
  &.p-inputtext {
    font-size: 14px;
  }
}

.p-message {
  &.error-duplicated {
    &.p-message-error {
      &.p-message-outlined {
        color: $color-black-primary-02;
        border-left: 10px solid $color-primary;
        outline-color: $color-primary;
        outline-offset: -1px;
      }
    }
  }
  &.message-guide {
    &.p-message-info {
      &.p-message-outlined {
        color: $color-black-primary-02;
        border-left: 10px solid $color-blue-primary-01;
        outline-color: $color-blue-primary-01;
        .p-message-content {
          padding: 12px 15px;
          .p-message-text {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.p-tooltip.custom-tooltip {
  .p-tooltip-arrow {
    border-top-color: $color-white-primary-01;
    border-bottom-color: $color-white-primary-01;
  }
  .p-tooltip-text {
    background: $color-white-primary-01;
    color: $color-black-primary-01;
  }
}

.p-multiselect {
  &.custom-multiselect {
    background: $color-white-primary-01;
    &:not(.p-disabled).p-focus,
    &:not(.p-disabled):hover {
      border-color: $color-white-primary-02;
      box-shadow: none;
    }
    .p-checkbox {
      .p-checkbox-input {
        border-color: $color-black-primary-02;
        opacity: 1;
        &:hover {
          background: none;
        }
      }
      &.p-checkbox-checked {
        &:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
          border-color: $color-black-primary-02;
          background: $color-black-primary-02;
        }
        .p-checkbox-box {
          border-color: $color-black-primary-02;
          background: $color-black-primary-02;
        }
      }
    }
  }
}

.p-inputnumber.p-inputtext.custom-input-number,
.p-inputtext.custom-input-text {
  background-color: $color-white-primary-01;
  &:enabled:focus,
  &:enabled:hover {
    border-color: $color-white-primary-02;
    box-shadow: none;
  }
  &.ng-invalid.ng-dirty {
    border-color: $color-red-primary-01;
    &::placeholder {
      color: $color-red-primary-01;
    }
  }
  &.required:enabled {
    border-color: $color-red-primary-01;
  }
}

.p-tabs {
  &.custom-tabs {
    .p-tab {
      border-width: 0 0 1px 0;
      background: transparent;
      font-size: 12px;
      font-weight: bold;
      line-height: 140.625%;
      text-transform: uppercase;
      &:not(.p-tab-active):not(.p-disabled):hover {
        background: transparent;
        border-color: $color-red-primary-01;
        color: inherit;
      }
      &:not(.p-disabled):focus-visible {
        box-shadow: none;
      }
      &.p-tab-active {
        background: transparent;
        border-color: $color-red-primary-01;
        color: $color-black-primary-01;
      }
    }
    .p-tabpanels {
      padding: 0.875rem 0 1.125rem;
    }
    .p-tabpanels,
    .p-tablist-tab-list {
      background: transparent;
    }
  }
}

.p-textarea {
  &.custom-textarea {
    background: $color-white-primary-01;
    &:enabled:focus {
      border-color: $color-white-primary-02;
      box-shadow: none;
    }
  }
}

.custom-search {
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid $color-white-primary-02;
  background-color: $color-white-primary-01;
  &:focus-visible {
    outline: none;
    border: 1px solid $color-prey-primary-03;
  }
}

.p-tooltip-text {
  font-size: 12px;
}
